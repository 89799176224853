import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import {
  login,
  currentAccount,
  logout,
  createUserWithEmailAndPassword,
  forgetPassword,
} from 'services/user'
import { GetSearchfirestoreLastRecord } from 'services/firestore'
import { getMenuDataChecker, getMenuDataOfficer, getMenuDataAdmin } from 'services/menu'
import actions from './actions'

export function* REGISTER_WITH_EMAIL_AND_PASSWORD({ payload }) {
  const success = yield call(createUserWithEmailAndPassword, payload)
  if (success) {
    yield call(logout)
    yield history.push('/system/login')
    notification.success({
      message: 'คุณสมัครเข้าใช้งานระบบของเราเรียบร้อยแล้ว',
      description: 'คุณต้องทำการยืนยันผ่านทางอีเมลก่อนเข้าใช้งานระบบ',
    })
  }
}

export function* LOGIN({ payload }) {
  const { email, password } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)
  yield put({
    type: 'user/LOAD_CURRENT_ACCOUNT',
  })

  if (success) {
    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: `ยินดีต้อนรับ คุณ ${email} เข้าสู่ระบบ!`,
    })
  }
}

export function* FORGET_PASSWORD({ payload }) {
  const { email } = payload
  const success = yield call(forgetPassword, email)
  if (success) {
    notification.success({
      message: 'ส่งอีเมลถึงคุณเรียบร้อยแล้ว',
      description: 'กรุณาตรวจสอบอีเมลของคุณเพื่อทำการแก้ไขรหัสผ่าน',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  const roleapp =
    response && response.email !== ''
      ? yield call(GetSearchfirestoreLastRecord, 'Officer', 'email', response.email)
      : null

  if (response && roleapp && roleapp.length > 0 && roleapp[0].data && roleapp[0].data.role !== '') {
    const { uid: id, email, photoURL: avatar, displayName: name } = response
    let role
    let menuData = []
    if (roleapp[0].data.role === 'checker') {
      role = 'เจ้าหน้าที่ตรวจสอบ'
      menuData = yield call(getMenuDataChecker)
    } else if (roleapp[0].data.role === 'officer') {
      role = 'เจ้าหน้าที่รับงาน'
      menuData = yield call(getMenuDataOfficer)
    } else if (roleapp[0].data.role === 'admin') {
      role = 'ผู้ดูแลระบบ'
      menuData = yield call(getMenuDataAdmin)
    } else {
      role = 'ไม่สามารถระบุได้'
    }
    // ดึงเมนูใหม่
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData,
      },
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        email,
        avatar,
        role,
        authorized: true,
      },
    })
  }

  if (response && roleapp && roleapp.length === 0) {
    notification.warning({
      message: 'Error',
      description: 'คุณไม่ได้รับอนุญาตให้ใช้งาน กรุณาติดต่อผู้ดูแลระบบ',
    })
    yield call(logout)
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.REGISTER_WITH_EMAIL_AND_PASSWORD, REGISTER_WITH_EMAIL_AND_PASSWORD),
    takeEvery(actions.FORGET_PASSWORD, FORGET_PASSWORD),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
