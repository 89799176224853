import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'
import config from 'config'

const firebaseApp = firebase.initializeApp(config.firebaseConfig)
const firebaseAuth = firebase.auth
const firebaseRealtimeDatabase = firebase.database
const firebaseFireStore = firebase.firestore
const firebaseStorage = firebase.storage

export default {
  firebaseApp,
  firebaseAuth,
  firebaseFireStore,
  firebaseRealtimeDatabase,
  firebaseStorage,
}

/* ################# Add Data FireStore ################## */
export function Addfirestore(table, data) {
  const db = firebase.firestore()
  const user = firebase.auth().currentUser
  // console.log(data.placeholder);
  // console.log(user);
  data.created_by = data.created_by || user.email
  data.created_date = new Date()

  if (user) {
    return (
      db
        .collection(table)
        .add(data)
        // eslint-disable-next-line
        .then(function(docRef) {
          console.log('Document written with ID: ', docRef.id)
          return docRef
        })
        // eslint-disable-next-line
        .catch(function(error) {
          console.error('Error adding document: ', error)
          return error
        })
    )
  }
}

/* ################# Add Job FireStore ################## */
export function AddfirestoreJob(table, data) {
  const db = firebase.firestore()
  const user = firebase.auth().currentUser
  // console.log(data.placeholder);
  // console.log(user);
  data.created_job_by = data.created_by || user.email
  data.created_job_date = new Date()

  if (user) {
    return (
      db
        .collection(table)
        .add(data)
        // eslint-disable-next-line
        .then(function(docRef) {
          console.log('Document written with ID: ', docRef.id)
          return docRef
        })
        // eslint-disable-next-line
        .catch(function(error) {
          console.error('Error adding document: ', error)
          return error
        })
    )
  }
}

export function AddfirestoreNoAuth(table, data) {
  const db = firebase.firestore()
  // console.log(data.placeholder);
  // console.log(user);
  data.created_date = new Date()

  return (
    db
      .collection(table)
      .add(data)
      // eslint-disable-next-line
      .then(function(docRef) {
        console.log('Document written with ID: ', docRef.id)
        return docRef
      })
      // eslint-disable-next-line
      .catch(function(error) {
        console.error('Error adding document: ', error)
        return error
      })
  )
}

/* ################# Update ################## */
export function Updatefirestore(table, data, id) {
  const db = firebase.firestore()
  const user = firebase.auth().currentUser
  console.log(data)

  if (table === 'SuggestionForm') {
    return db
      .collection(table)
      .doc(id)
      .update({
        energy_source: data.energy_source,
        fatal: data.fatal,
        type_of_observation: data.type_of_observation,
        terminal: data.terminal,
        date_time: new Date(data.date_time),
        description: data.description,
        process: data.process,
        updated_by: user.email,
        updated_date: new Date(),
      })
      .then(docRef => docRef)
      .catch(error => {
        // console.error("Error update document: ", error);
        const errorRes = error
        errorRes.APPCONFIG = 'error'
        return errorRes
      })
  }
  if (data.placeholder && data.value !== '') {
    return db
      .collection(table)
      .doc(id)
      .update({
        placeholder: data.placeholder,
        value: data.value,
        updated_by: user.email,
        updated_date: new Date(),
      })
      .then(docRef => docRef)
      .catch(error => {
        // console.error("Error update document: ", error);
        const errorRes = error
        errorRes.APPCONFIG = 'error'
        return errorRes
      })
  }
}

/* ################# Update User ################## */
export function UpdateUserfirestore(table, data, id) {
  const db = firebase.firestore()
  const user = firebase.auth().currentUser
  console.log(data)

  if (data.displayName && data.email !== '') {
    return db
      .collection(table)
      .doc(id)
      .update({
        displayName: data.displayName,
        email: data.email,
        role: data.role,
        updated_by: user.email,
        updated_date: new Date(),
      })
      .then(docRef => docRef)
      .catch(error => {
        // console.error("Error update document: ", error);
        const errorRes = error
        errorRes.APPCONFIG = 'error'
        return errorRes
      })
  }
}

/* ################# Update Status ################## */
export function UpdateStatusfirestore(table, data, id) {
  const db = firebase.firestore()
  const user = firebase.auth().currentUser

  if (data.note || data.noteFileAttachment) {
    return db
      .collection(table)
      .doc(id)
      .update({
        worker: user.displayName,
        status: data.status,
        note: data.note,
        noteFileAttachment: data.noteFileAttachment,
      })
      .then(docRef => docRef)
      .catch(error => {
        // console.error("Error update document: ", error);
        const errorRes = error
        errorRes.APPCONFIG = 'error'
        return errorRes
      })
  }
  return db
    .collection(table)
    .doc(id)
    .update({
      worker: user.displayName,
      status: data.status,
    })
    .then(docRef => docRef)
    .catch(error => {
      // console.error("Error update document: ", error);
      const errorRes = error
      errorRes.APPCONFIG = 'error'
      return errorRes
    })
}

/* ################# Update Close ################## */
export function UpdateClosefirestore(table, data, id) {
  const db = firebase.firestore()
  const user = firebase.auth().currentUser

  if (data.noteClose || data.noteFileAttachmentClose) {
    return db
      .collection(table)
      .doc(id)
      .update({
        worker: user.displayName,
        status: data.status,
        noteClose: data.noteClose,
        noteFileAttachmentClose: data.noteFileAttachmentClose,
      })
      .then(docRef => docRef)
      .catch(error => {
        // console.error("Error update document: ", error);
        const errorRes = error
        errorRes.APPCONFIG = 'error'
        return errorRes
      })
  }
  return db
    .collection(table)
    .doc(id)
    .update({
      worker: user.displayName,
      status: data.status,
    })
    .then(docRef => docRef)
    .catch(error => {
      // console.error("Error update document: ", error);
      const errorRes = error
      errorRes.APPCONFIG = 'error'
      return errorRes
    })
}

/* ################# Get ################## */
export function Getfirestore(table, id) {
  const db = firebase.firestore()

  if (id) {
    return db
      .collection(table)
      .doc(id)
      .get()
      .then(querySnapshot => querySnapshot)
  }
  return db
    .collection(table)
    .get()
    .then(querySnapshot => querySnapshot)
}

export function GetfirestoreOrder(table, id, nameOrder, typeOrder) {
  const db = firebase.firestore()

  if (id) {
    return db
      .collection(table)
      .orderBy(nameOrder, typeOrder)
      .doc(id)
      .get()
      .then(querySnapshot => querySnapshot)
  }
  return db
    .collection(table)
    .orderBy(nameOrder, typeOrder)
    .get()
    .then(querySnapshot => querySnapshot)
}

/* ################# Get Search ################## */
export function GetSearchfirestore(table, where, value) {
  const db = firebase.firestore()
  const arr = []

  // return db.collection(table).where(where, operation, value)
  if (where === 'hybrid') {
    db.collection(table)
      .orderBy('detail')
      .startAt(value)
      .endAt(`${value}\uf8ff`)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const setup = {
            id: doc.id,
            data: doc.data(),
          }
          arr.push(setup)
        })
      })
      .catch(
        error =>
          // console.log("Error getting documents: ", error)
          error,
      )

    db.collection(table)
      .orderBy('descriptionOfUnsafe')
      .startAt(value)
      .endAt(`${value}\uf8ff`)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const setup = {
            id: doc.id,
            data: doc.data(),
          }
          arr.push(setup)
        })
      })
      .catch(
        error =>
          // console.log("Error getting documents: ", error)
          error,
      )
  }
  return db
    .collection(table)
    .orderBy(where)
    .startAt(value)
    .endAt(`${value}\uf8ff`)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const setup = {
          id: doc.id,
          data: doc.data(),
        }
        arr.push(setup)
      })
      return arr
    })
    .catch(
      error =>
        // console.log("Error getting documents: ", error)
        error,
    )
}

/* ################# Get Search ################## */
export function GetSearchWherefirestore(table, order, value) {
  const db = firebase.firestore()
  const user = firebase.auth().currentUser
  const arr = []
  // console.log(where , operation , user);
  // return db.collection(table).where(where, operation, value)
  if (order === 'hybrid') {
    db.collection(table)
      .orderBy('detail')
      .startAt(value)
      .endAt(`${value}\uf8ff`)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().request_worker === user.email) {
            const setup = {
              id: doc.id,
              data: doc.data(),
            }
            arr.push(setup)
          }
        })
      })
      .catch(
        error =>
          // console.log("Error getting documents: ", error)
          error,
      )

    db.collection(table)
      .orderBy('descriptionOfUnsafe')
      .startAt(value)
      .endAt(`${value}\uf8ff`)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().request_worker === user.email) {
            const setup = {
              id: doc.id,
              data: doc.data(),
            }
            arr.push(setup)
          }
        })
      })
      .catch(
        error =>
          // console.log("Error getting documents: ", error)
          error,
      )
  }
  return db
    .collection(table)
    .orderBy(order)
    .startAt(value)
    .endAt(`${value}\uf8ff`)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        if (doc.data().request_worker === user.email) {
          const setup = {
            id: doc.id,
            data: doc.data(),
          }
          arr.push(setup)
        }
      })
      return arr
    })
    .catch(
      error =>
        // console.log("Error getting documents: ", error)
        error,
    )
}

export function GetSearchfirestoreLastRecord(table, where, value) {
  const db = firebase.firestore()

  const arr = []
  // return db.collection(table).where(where, operation, value)
  return db
    .collection(table)
    .orderBy(where)
    .limit(1)
    .startAt(value)
    .endAt(`${value}\uf8ff`)
    .get()
    .then(querySnapshot => {
      // console.log(querySnapshot)
      querySnapshot.forEach(doc => {
        // console.log(doc.id, " => ", doc.data());
        const setup = {
          id: doc.id,
          data: doc.data(),
        }
        arr.push(setup)
      })
      return arr
    })
    .catch(
      error =>
        // console.log("Error getting documents: ", error)
        error,
    )
}

export function GetSearchfirestoreSize(table, where, value) {
  const db = firebase.firestore()

  // return db.collection(table).where(where, operation, value)
  return db
    .collection(table)
    .orderBy(where)
    .startAt(value)
    .endAt(`${value}\uf8ff`)
    .get()
    .then(querySnapshot => {
      return querySnapshot.size
    })
    .catch(
      error =>
        // console.log("Error getting documents: ", error);
        error,
    )
}

/* ################# Get Where ################## */
export function GetWherefirestore(table, where, operation, value) {
  const db = firebase.firestore()
  const arr = []
  return db
    .collection(table)
    .orderBy('created_job_date', 'desc')
    .where(where, operation, value)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const setup = {
          id: doc.id,
          data: doc.data(),
        }
        arr.push(setup)
      })
      return arr
    })
    .catch(error => console.log('Error getting documents: ', error))
}

/* ################# Delete ################## */
export function Deletefirestore(table, id) {
  const db = firebase.firestore()

  return (
    db
      .collection(table)
      .doc(id)
      .delete()
      // eslint-disable-next-line
      .then(function() {
        return 'success'
      })
      // eslint-disable-next-line
      .catch(function(error) {
        return error
      })
  )
}
