import localeAntd from 'antd/es/locale/th_TH'

const messages = {
  'topBar.issuesHistory': 'ประวัติ',
  'topBar.typeToSearch': 'เริ่มค้นหาข้อมูล...',
  'topBar.actions': 'ปุ่มทำงาน',
  'topBar.status': 'สถานะ',
  'topBar.profileMenu.hello': 'สวัสดี',
  'topBar.profileMenu.billingPlan': 'แผนการเงิน',
  'topBar.profileMenu.role': 'บทบาท',
  'topBar.profileMenu.email': 'อีเมล',
  'topBar.profileMenu.phone': 'เบอร์โทรศัพท์',
  'topBar.profileMenu.editProfile': 'แก้ไขข้อมูลส่วนตัว',
  'topBar.profileMenu.logout': 'ออกจากระบบ',
}

export default {
  locale: 'th-TH',
  localeAntd,
  messages,
}
