export default {
  app_name: 'Service - Mediplex Thailand Co., Ltd.',
  app_url: 'https://service-mediplex.firebaseapp.com',
  app_description: 'ระบบบริหารจัดการงานบริการของ บริษัท เมดดิเพล็กซ์ (ไทยแลนด์) จำกัด',
  app_logo: {
    path: 'resources/images/air-logo.png',
    alt: 'App Logo',
  },
  footer: {
    logoName: 'Service',
    logoDescr: 'Mediplex Thailand Co., Ltd.',
    license: 'Mediplex Thailand Co., Ltd.. All rights reserved.',
  },
  contactURL: '#',
  firebaseConfig: {
    apiKey: 'AIzaSyAxAZC3HeG27rUsbenIWQvduxR3kL7nUKM',
    authDomain: 'service-mediplex.firebaseapp.com',
    databaseURL: 'https://service-mediplex.firebaseapp.com',
    projectId: 'service-mediplex',
    storageBucket: 'service-mediplex.appspot.com',
    messagingSenderId: '261130022501',
    appId: '1:261130022501:web:764c5e7c61f2cc2a21608c',
    measurementId: 'G-63HYMED0PS',
  },
  lineConfig: {
    MESSAGING_API_url: 'https://api.line.me/v2/bot/message/push',
    LINE_HEADER: {
      'Content-Type': 'application/json',
      Authorization:
        'Bearer r/eNiszuIGMqRE0MHq229hBgPH/SeBh4om6pdPSH2CZlJiXeSiGJDt3uDG/harA7ortnLhnT+5ObN5JY5Vpgw6cMvdOTn6TWjOL5ovkbpimmKLEjagSalZvwzIs/fC0SWpAJqkVe+ySEXwtM7Un2MAdB04t89/1O/w1cDnyilFU=', // <CHANNEL-ACCESS-TOKEN>
    },
  },
  TABLE_PAGINATION: ['10', '30', '50', '100'],
  policyPicture: 'resources/images/project/policy.jpg',
  definitionPicture: 'resources/images/project/definition.png',
}
