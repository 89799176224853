import { notification } from 'antd'
import firebase from './firestore'

const { firebaseAuth } = firebase

export async function login(email, password) {
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .then(res => {
      console.log(res)
      if (res.user && res.user.emailVerified !== true) {
        const err = {
          code: 'Username ของคุณยังไม่ได้รับการยืนยัน',
          message: 'ตรวจสอบอีเมลของคุณเพื่อทำการยืนยันก่อนเข้าใช้งานระบบ',
        }
        throw err
      } else {
        return true
      }
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function createUserWithEmailAndPassword(payload) {
  const { email, password, displayName } = payload
  return firebaseAuth()
    .createUserWithEmailAndPassword(email, password)
    .then(res => {
      res.user
        .updateProfile({
          displayName,
          // photoURL: "https://example.com/jane-q-user/profile.jpg"
        })
        .then(() => {
          // Update successful.
          console.log('Update Send')
        })
        .catch(error => {
          // An error happened.
          console.log('ERROR: ', error)
        })

      res.user
        .sendEmailVerification()
        .then(() => {
          // Email sent.
          console.log('email Send')
        })
        .catch(error => {
          // An error happened.
          console.log('ERROR: ', error)
        })

      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function forgetPassword(email) {
  return firebaseAuth()
    .sendPasswordResetEmail(email)
    .then(() => {
      // Email sent.
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth())
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => true)
}
